import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`13th November 2024`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Dynamic TLS Configuration`}</strong>{`: You can now update TLS configurations dynamically using `}<a parentName="p" {...{
            "href": "type://TlsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/TlsProvider.html"
          }}>{`type://TlsProvider`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5033"
          }}>{`#5033`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5228"
          }}>{`#5228`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`You can create a `}<a parentName="p" {...{
                "href": "type://TlsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/TlsProvider.html"
              }}>{`type://TlsProvider`}</a>{` which specifies `}<a parentName="p" {...{
                "href": "type://TlsKeyPair:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/TlsKeyPair.html"
              }}>{`type://TlsKeyPair`}</a>{` dynamically.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// return a TlsKeyPair for host patterns
TlsProvider tlsProvider = TlsProvider
  .builder()
  .keyPair("*.a.com", TlsKeyPair.of(...))
  .keyPair("*.b.com", TlsKeyPair.of(...))
  .build();

// return a TlsKeyPair dynamically
TlsProvider tlsProvider = hostname -> TlsKeyPair.of(...);
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The `}<a parentName="p" {...{
                "href": "type://TlsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/TlsProvider.html"
              }}>{`type://TlsProvider`}</a>{` can be used for both servers and clients.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// client-side
ClientFactory
  .builder()
  .tlsProvider(tlsProvider);

// server-side
Server
  .builder()
  .tlsProvider(tlsProvider);
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Access to Raw Request Path`}</strong>{`: You can access the raw request path via `}<a parentName="p" {...{
            "href": "type://ServiceRequestContext#rawPath():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#rawPath()"
          }}>{`type://ServiceRequestContext#rawPath()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5931"
          }}>{`#5931`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5932"
          }}>{`#5932`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This can be useful when you need to get the original request path sent from the client, even if it's potentially insecure.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`ServiceRequestContext ctx = ...;
String rawPath = ctx.rawPath();
// rawPath may contain unencoded, invalid or insecure characters.
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Additional Static Factory Methods for ResponseEntity`}</strong>{`: More static factory methods are added for `}<a parentName="p" {...{
            "href": "type://ResponseEntity:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseEntity.html"
          }}>{`type://ResponseEntity`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5954"
          }}>{`#5954`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ResponseEntity<Object> response = ResponseEntity.of(200);
ResponseEntity<String> response = ResponseEntity.of("Hello!");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Timeout Support for HttpRequest and HttpResponse`}</strong>{`: You can specify `}<a parentName="p" {...{
            "href": "type://HttpRequest#timeout(Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#timeout(java.time.Duration)"
          }}>{`type://HttpRequest#timeout(Duration)`}</a>{` or `}<a parentName="p" {...{
            "href": "type://HttpResponse#timeout(Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#timeout(java.time.Duration)"
          }}>{`type://HttpResponse#timeout(Duration)`}</a>{` to set a timeout
for an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` or `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5744"
          }}>{`#5744`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5761"
          }}>{`#5761`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest
  .streaming(HttpMethod.GET, "/")
  .timeout(Duration.ofSeconds(1)) // timeout if there is a delay exceeding 1 second between each data chunk
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Nacos Integration for Server Registration and Endpoint Retrieval`}</strong>{`: You can now register your server to or retrieve `}<a parentName="p" {...{
            "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
          }}>{`typeplural://Endpoint`}</a>{` from
a `}<a parentName="p" {...{
            "href": "https://nacos.io/"
          }}>{`Nacos`}</a>{` registry server. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5365"
          }}>{`#5365`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5409"
          }}>{`#5409`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://NacosUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/nacos/NacosUpdatingListener.html"
            }}>{`type://NacosUpdatingListener`}</a>{` to register the `}<a parentName="li" {...{
              "href": "typeplural://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
            }}>{`typeplural://Server`}</a>{` to `}<a parentName="li" {...{
              "href": "https://nacos.io/"
            }}>{`Nacos`}</a>{`:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server server = ...;
server.addListener(
  NacosUpdatingListener
    .builder(nacosUri, "my-service")
    ...
    .build());
`}</code></pre>
          </li>
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://NacosEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/nacos/NacosEndpointGroup.html"
            }}>{`type://NacosEndpointGroup`}</a>{` for clients:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`EndpointGroup group = NacosEndpointGroup.of(nacosUri, "my-service");
WebClient client = WebClient.of(SessionProtocol.HTTP, group);
`}</code></pre>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` now supports `}<inlineCode parentName="li">{`disable_active_health_check`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5879"
        }}>{`#5879`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`DNS resolver now correctly adds search domains for hostnames with trailing dots. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5963"
        }}>{`#5963`}</a></li>
      <li parentName="ul">{`CORS headers for failed requests are now correctly set even when a custom `}<a parentName="li" {...{
          "href": "type://ServerErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html"
        }}>{`type://ServerErrorHandler`}</a>{` is configured. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5493"
        }}>{`#5493`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5939"
        }}>{`#5939`}</a></li>
      <li parentName="ul">{`Fixed a bug where `}<a parentName="li" {...{
          "href": "type://AsyncServerInterceptor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/AsyncServerInterceptor.html"
        }}>{`type://AsyncServerInterceptor`}</a>{` is incompatible with the OpenTelemetry gRPC agent. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5937"
        }}>{`#5937`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5938"
        }}>{`#5938`}</a></li>
      <li parentName="ul">{`The GraalVM native image metadata has been updated to reflect recent code changes. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5946"
        }}>{`#5946`}</a></li>
      <li parentName="ul">{`Spring Actuator correctly collects metrics when using Spring WebFlux integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5882"
        }}>{`#5882`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5884"
        }}>{`#5884`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`ABI compatibility of `}<a parentName="li" {...{
          "href": "type://ResponseEntity#of(ResponseHeaders):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseEntity.html#of(com.linecorp.armeria.common.ResponseHeaders)"
        }}>{`type://ResponseEntity#of(ResponseHeaders)`}</a>{` has been broken. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5954"
        }}>{`#5954`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brotli4j 1.16.0 → 1.17.0`}</li>
      <li parentName="ul">{`java-control-plane 1.0.45 → 1.0.46`}</li>
      <li parentName="ul">{`Eureka 2.0.3 → 2.0.4`}</li>
      <li parentName="ul">{`GraphQL Kotlin 7.1.4 → 8.2.1`}</li>
      <li parentName="ul">{`Java gRPC 1.65.1 → 1.68.1`}</li>
      <li parentName="ul">{`Jackson 2.17.2 → 2.18.1`}</li>
      <li parentName="ul">{`Jetty`}
        <ul parentName="li">
          <li parentName="ul">{`11.0.22 → 11.0.24`}</li>
          <li parentName="ul">{`12.0.12 → 12.0.14`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Kotlin 3.8.0 → 3.8.1`}</li>
      <li parentName="ul">{`Kotlin coroutines 1.8.1 → 1.9.0`}</li>
      <li parentName="ul">{`Fabric8 Kubernetes Client 6.13.1 → 6.13.4`}</li>
      <li parentName="ul">{`Micrometer 1.13.2 → 1.13.6`}</li>
      <li parentName="ul">{`Micrometer Tracing 1.3.2 → 1.3.5`}</li>
      <li parentName="ul">{`Netty 4.1.112.Final → 4.1.115.Final`}</li>
      <li parentName="ul">{`prometheus 1.3.1 → 1.3.2`}</li>
      <li parentName="ul">{`Protobuf 3.25.1 → 3.25.5`}</li>
      <li parentName="ul">{`protobuf-jackson 2.5.0 → 2.6.0`}</li>
      <li parentName="ul">{`Reactor 3.6.8 → 3.6.11`}</li>
      <li parentName="ul">{`RXJava 3.1.8 → 3.1.9`}</li>
      <li parentName="ul">{`Sangria 4.1.1 → 4.2.2`}</li>
      <li parentName="ul">{`Scala`}
        <ul parentName="li">
          <li parentName="ul">{`2.12.19 → 2.12.20`}</li>
          <li parentName="ul">{`2.13.14 → 2.13.15`}</li>
          <li parentName="ul">{`3.4.2 → 3.6.1`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Spring 6.1.11 → 6.1.14`}</li>
      <li parentName="ul">{`Spring Boot 3.3.2 → 3.3.5`}</li>
      <li parentName="ul">{`Tomcat`}
        <ul parentName="li">
          <li parentName="ul">{`9.0.91 → 9.0.96`}</li>
          <li parentName="ul">{`10.1.26 → 10.1.31`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Bue-von-hon', 'JiwonKKang', 'KarboniteKream', 'KonaEspresso94', 'Mina-1316', 'be-hase', 'blue-hope', 'chickenchickenlove', 'friscoMad', 'ikhoon', 'jrhee17', 'kabigon-sung', 'minwoox', 'my4-dev', 'roderick675', 'sjy982', 'trustin', 'yzfeng2020']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      